import angular from 'angular';

import buymeacoffee from './buymeacoffee/buymeacoffee';

let commonModule = angular.module('app.common', [
  buymeacoffee
])
  .name;

export default commonModule;
