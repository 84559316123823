export default function ($scope, $rootScope, $state, tagsService) {
  let vm;

  class HomeController {
    constructor () {
      vm = this;
      vm.name = 'home';
      vm.$state = $state;

      let imageUrlPrefix = '/assets/img/';

      vm.contactlist = [
        {
          name: 'inforoom',
          url: 'http://forum.netfree.link/user/zvizvi',
          label: 'אינפורום',
          img: imageUrlPrefix + 'inforoom.png'
        },
        {
          name: 'tchumim',
          url: 'http://tchumim.com/user/zvizvi',
          label: 'תחומים',
          img: imageUrlPrefix + 'tchumim.png'
        },
        {
          name: 'professions',
          url: 'http://xn--9dbazrsfz.com/user/zvizvi',
          label: 'מקצועות',
          img: imageUrlPrefix + 'professions.png'
        },
        {
          name: 'email',
          url: 'mailto:zvizvi5@outlook.com',
          label: 'דואר אלקטרוני',
          img: imageUrlPrefix + 'email.png'
        }
      ];

      $scope.$on('current changed', () => {
        vm.current = $rootScope.current;
      });
    }

    $onInit () {
      vm.tags = tagsService.list();
    }
  }

  return new HomeController();
}
