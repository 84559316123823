import angular from 'angular';
import uiRouter from 'angular-ui-router';
import AngularMaterial from 'angular-material';
import appConfig from './app.config';
import Common from './common/common';
import Components from './components/components';
import Directives from './directives/directives';
import Services from './services/services';
import AppComponent from './app.component';
import 'angular-material/angular-material.min.css';
import 'ng-error/ng-error';

angular.module('app', [
  uiRouter,
  AngularMaterial,
  Common,
  Components,
  Directives,
  Services,
  'ngError'
])
  .config(appConfig)
  .component('app', AppComponent);
