import template from './home.html';
import controller from './home.controller';
import './home.scss';

let homeComponent = {
  bindings: {},
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', '$rootScope', '$state', 'tagsService'];

export default homeComponent;
