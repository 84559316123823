export default function () {
  let vm;

  class CoffeeController {
    constructor () {
      vm = this;
      vm.name = 'coffee';
      vm.backgroundList = [
        { type: 'image', src: 'https://images.unsplash.com/photo-1530268430791-f7d3be9b5f52' },
        { type: 'image', src: 'https://images.unsplash.com/photo-1493606278519-11aa9f86e40a' },
        { type: 'image', src: 'https://images.unsplash.com/photo-1497515114629-f71d768fd07c' },
        { type: 'image', src: 'https://images.unsplash.com/photo-1444418776041-9c7e33cc5a9c' },
        { type: 'image', src: 'https://images.unsplash.com/photo-1422207049116-cfaf69531072' },
        { type: 'image', src: 'https://images.unsplash.com/photo-1480348709911-be15f2c579ff' },
        { type: 'image', src: 'https://images.unsplash.com/photo-1500989145603-8e7ef71d639e' },
        { type: 'image', src: 'https://images.unsplash.com/photo-1454438992604-4a8e59768a5a' },
        { type: 'image', src: 'https://images.unsplash.com/photo-1492158244976-29b84ba93025' },
        { type: 'image', src: 'https://images.unsplash.com/photo-1497032628192-86f99bcd76bc' },
        { type: 'image', src: 'https://images.unsplash.com/photo-1448316998199-a2aaf2b5d4a6' },
        { type: 'image', src: 'https://images.unsplash.com/photo-1459755486867-b55449bb39ff' },
        { type: 'image', src: 'https://images.unsplash.com/photo-1503481766315-7a586b20f66d' },
        { type: 'image', src: 'https://images.unsplash.com/photo-1525445842399-d8a6bec24be2' },
        { type: 'image', src: 'https://images.unsplash.com/photo-1426260193283-c4daed7c2024' },
        { type: 'image', src: '/assets/img/OGDRVD0.jpg' },
        { type: 'video', src: '/assets/video/DSCF23182.mp4', poster: '/assets/img/DSCF23182.jpg' },
        { type: 'video', src: '/assets/video/CGHGFLNKB.mp4', poster: '/assets/img/CGHGFLNKB.jpg' }
      ];
    }

    $onInit () {
      let background = vm.backgroundList[Math.floor(Math.random() * (vm.backgroundList.length))];
      vm.background = background.src + (background.type === 'image' ? '?&fit=crop&fm=jpg&h=' + window.outerHeight + '&w=' + window.outerWidth : '');
      vm.backgroundType = background.type;
      vm.poster = background.poster;
    }
  }

  return new CoffeeController();
}
