import template from './coffee.html';
import controller from './coffee.controller';
import './coffee.scss';

let coffeeComponent = {
  bindings: {},
  template,
  controller,
  controllerAs: 'vm'
};

// controller.$inject = [];

export default coffeeComponent;
