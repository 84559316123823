import angular from 'angular';

let vm;

let tags = {
  default: {
    label: 'כלים ויישומים'
  },
  chrome: {
    label: 'תוספים לכרום'
  },
  netfree: {
    label: 'כלים למשתמשי נטפרי'
  }
};

class tagsService {
  constructor () {
    vm = this;
  }

  list () {
    return tags;
  }
}

// tagsService.$inject = [''];

let tagsModule = angular.module('tagsService', [])
  .service('tagsService', tagsService)
  .name;

export default tagsModule;
