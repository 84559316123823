import angular from 'angular';

let vm;

let apps = [
  {
    title: 'תוכנות להורדה',
    description: 'אוסף תוכנות להורדה\n עבור משתמשי נטפרי',
    link: 'https://softwares.center',
    image: '/assets/img/apps/softwares.png',
    category: '',
    tags: ['netfree', 'website']
  },
  {
    title: 'לוח שנה עברי',
    description: 'תוסף לדפדפן כרום שמציג\n לוח שנה עברי',
    link: 'https://softwares.center/hebrew-calendar/',
    image: '/assets/img/apps/hebrew-calendar.png',
    category: '',
    tags: ['chromeExtantion']
  },
  {
    title: 'ספירת העומר',
    description: 'תוסף לדפדפן כרום המציג\n את ספירת העומר היומית',
    link: 'https://chrome.google.com/webstore/detail/hnopjdlbidclmnomnahajmhadkniggeo',
    image: '/assets/img/apps/omer.png',
    category: '',
    tags: ['chromeExtantion']
  },
  {
    title: 'שידור חי',
    description: 'וידאו בזמן אמת ממקומות נבחרים\n עבור משתמשי נטפרי',
    link: 'http://kotel-cams.ml/',
    image: '/assets/img/apps/live-stream.png',
    category: '',
    tags: ['netfree', 'website']
  },
  {
    title: 'דף הבית',
    description: 'דף בית עם כלים שימושיים רבים',
    link: 'https://xn----2hcef0a0dvc.xn--9dbq2a/',
    image: '/assets/img/apps/homepage.png',
    category: '',
    tags: ['website']
  },
  {
    title: 'הורדת תוספים לכרום',
    description: 'הורדת קבצי crx מחנות התוספים של כרום',
    link: 'https://zvizvi.gitlab.io/crx-download/',
    image: '/assets/img/apps/crx-download.png',
    category: '',
    tags: ['website']
  },
  {
    title: 'הנגאאוטס ימין לשמאל',
    description: 'תוסף לכרום שמתקן את הנגאאוטס (ג\'ימייל צ\'אט) מימין לשמאל',
    link: 'https://chrome.google.com/webstore/detail/oohpklfcenaffjconedjebkaclkachmi',
    image: '/assets/img/apps/hangouts-rtl.png',
    category: '',
    tags: ['chromeExtantion']
  },
  // {
  //   title: 'Move Netfree panel',
  //   description: 'תוסף ניהול הכרטיסיה של נטפרי',
  //   link: 'https://softwares.center/move-netfree-panel/',
  //   image: '/assets/img/apps/move-netfree-panel.png',
  //   category: '',
  //   tags: ['netfree', 'chromeExtantion']
  // },
  {
    title: 'חתימה למייל',
    description: 'חתימה למייל עבור משתמשי נטפרי',
    link: 'https://zvizvi.xyz/signature/',
    image: '',
    category: '',
    tags: ['netfree']
  },
  {
    title: 'תוסף אנשי קשר בGmail',
    description: 'מוסיף קיצור דרך לאנשי הקשר מתוך הממשק החדש של Gmail',
    link: 'https://chrome.google.com/webstore/detail/jgakkffecofibnpbdkkphaminipefbjj',
    image: '/assets/img/apps/contacts.png',
    category: '',
    tags: ['chromeExtantion']
  },
  {
    title: 'השלמה אוטומטית',
    description: 'השלמה אוטומטית לכתובות דוא"ל והדבקה קלה למילים שמורות',
    link: 'https://chrome.google.com/webstore/detail/pnnbnklagfdokfbdfphhojhifjppjmmc',
    image: '/assets/img/apps/email-address-autocomplete.png',
    category: '',
    tags: ['chromeExtantion']
  },
  {
    title: 'Anti Lazy Loading',
    description: 'טוען את כל התמונות בדף מיד עם אתחול הצגת הדף',
    link: 'https://chrome.google.com/webstore/detail/amhbdjjhdcjcpjkjibbhnkkidigbfgmb',
    image: '/assets/img/apps/anti-lazy-loading.png',
    category: '',
    tags: ['chromeExtantion']
  },
  {
    title: 'אתרים נפוצים - נטפרי',
    description: 'תוסף השלמה אוטומטית בGoogle עבור "אתרים נפוצים" של נטפרי',
    link: 'https://chrome.google.com/webstore/detail/kmpmofoajdopdjcgpbobcmlllfnjdknn',
    image: '/assets/img/apps/netfree-common-sites.png',
    category: '',
    tags: ['netfree', 'chromeExtantion']
  }
];

class appsService {
  constructor () {
    vm = this;
  }

  list () {
    return apps;
  }
}

// appsService.$inject = [''];

let appsModule = angular.module('appsService', [])
  .service('appsService', appsService)
  .name;

export default appsModule;
