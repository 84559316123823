import template from './apps.html';
import controller from './apps.controller';
import './apps.scss';

let appsComponent = {
  bindings: {},
  template,
  controller,
  controllerAs: 'vm'
};

controller.$inject = ['$scope', '$rootScope', '$state', '$window', '$timeout',
  'appsService', 'tagsService'];

export default appsComponent;
