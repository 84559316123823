import angular from 'angular';
import Coffee from './coffee/coffee';
import Home from './home/home';
import Apps from './apps/apps';

let componentModule = angular.module('app.components', [
  Coffee,
  Home,
  Apps
])
  .name;

export default componentModule;
