import _ from 'lodash';

export default function ($scope, $rootScope, $state, $window, $timeout,
  appsService, tagsService) {
  let vm;

  class AppsController {
    constructor () {
      vm = this;
      vm.name = 'apps';
      vm.emptyImage = '/assets/img/apps/tool.png';
    }

    $onInit () {
      vm.tagFilter = $state.params.tag;
      $rootScope.current = $state.params.tag;
      $scope.$emit('current changed');

      vm.title = _.get(tagsService.list(), vm.tagFilter + '.label');
      if (!vm.tagFilter) {
        vm.title = tagsService.list().default.label;
      }
      document.title = 'zvizvi - אתר הבית - ' + vm.title;

      vm.apps = appsService.list();
    }

    setEmptyImage (app) {
      app.image = vm.emptyImage;
    }
  }

  return new AppsController();
}
