import angular from 'angular';
import appsService from './apps';
import tagsService from './tags';

let servicesModule = angular.module('app.services', [
  appsService,
  tagsService
])
  .name;

export default servicesModule;
