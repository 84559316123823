export default ['$locationProvider', '$stateProvider', '$urlRouterProvider', '$compileProvider', function ($locationProvider, $stateProvider, $urlRouterProvider, $compileProvider) {
  'ngInject';
  // @see: https://github.com/angular-ui/ui-router/wiki/Frequently-Asked-Questions
  // #how-to-configure-your-server-to-work-with-html5mode
  // $locationProvider.html5Mode(true).hashPrefix('!');
  $locationProvider.hashPrefix('');
  $compileProvider.debugInfoEnabled(true);
  $stateProvider
    .state('home', {
      url: '/',
      component: 'home'
    })
    .state('home.apps', {
      url: 'apps/:tag?',
      component: 'apps'
    })
    .state('home.coffee', {
      url: 'coffee',
      component: 'coffee'
    })
    .state('home.☕', {
      url: '☕',
      component: 'coffee'
    });
  $urlRouterProvider.otherwise('/apps/');
}];
