export default function () {
  let vm;

  class BuymeacoffeeController {
    constructor () {
      vm = this;
      vm.name = 'buymeacoffee';
    }
  }

  return new BuymeacoffeeController();
}
