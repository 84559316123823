import template from './buymeacoffee.html';
import controller from './buymeacoffee.controller';
import './buymeacoffee.scss';

let buymeacoffeeComponent = {
  bindings: {},
  template,
  controller,
  controllerAs: 'vm'
};

// controller.$inject = [];

export default buymeacoffeeComponent;
