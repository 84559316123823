import angular from 'angular';

import buymeacoffeeComponent from './buymeacoffee.component';

let buymeacoffeeModule = angular.module('buymeacoffee', [
])
  .component('buymeacoffee', buymeacoffeeComponent)
  .name;

export default buymeacoffeeModule;
